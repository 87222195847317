var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "CAlert",
        { staticClass: "mb-0", attrs: { show: "", color: "warning" } },
        [
          _c("h5", [_vm._v("Unsigned Contract!")]),
          _c(
            "div",
            { staticClass: "d-flex flex-gap-2 mt-3" },
            [
              _c("CIcon", { attrs: { height: 24, name: "cil-warning" } }),
              _c(
                "span",
                [
                  _c(
                    "CLink",
                    {
                      attrs: {
                        to: {
                          name: "Edit Restaurant",
                          params: { id: _vm.id },
                          query: { tab: 8 },
                        },
                      },
                    },
                    [_vm._v("Sign the contract")]
                  ),
                  _vm._v(" or "),
                  _c(
                    "a",
                    {
                      attrs: { role: "button" },
                      on: {
                        click: function ($event) {
                          _vm.showConfirm = true
                        },
                      },
                    },
                    [_vm._v("send mail to sign")]
                  ),
                  _vm._v(". "),
                ],
                1
              ),
            ],
            1
          ),
          _c("CElementCover", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.processing,
                expression: "processing",
              },
            ],
            attrs: { opacity: 0.4 },
          }),
        ],
        1
      ),
      _c(
        "CModal",
        {
          staticClass: "confirm-modal",
          attrs: {
            title: "Confirm",
            color: "dark",
            show: _vm.showConfirm,
            closeOnBackdrop: false,
            centered: false,
          },
          on: {
            "update:show": function ($event) {
              _vm.showConfirm = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "link" },
                      on: {
                        click: function ($event) {
                          _vm.showConfirm = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: { size: "sm", color: "info" },
                      on: { click: _vm.sendMail },
                    },
                    [
                      _c("CIcon", { attrs: { name: "cil-send" } }),
                      _vm._v(" Send Mail "),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.emails.length > 1
            ? _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "d-block" }, [
                  _vm._v("Select an email to send:"),
                ]),
                _c(
                  "div",
                  { staticClass: "mail-group" },
                  _vm._l(_vm.emails, function (mail, index) {
                    return _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row flex-gap-2 align-items-center",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedMail,
                              expression: "selectedMail",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: `mail_${index}`,
                            name: "email",
                          },
                          domProps: {
                            value: mail,
                            checked: _vm._q(_vm.selectedMail, mail),
                          },
                          on: {
                            change: function ($event) {
                              _vm.selectedMail = mail
                            },
                          },
                        }),
                        _c("label", { attrs: { for: `mail_${index}` } }, [
                          _vm._v(_vm._s(mail)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "alert alert-info mb-0" }, [
            _c("p", [
              _vm._v("⚠️ Mail will be sent to "),
              _c("strong", [_vm._v(_vm._s(_vm.selectedMail))]),
              _vm._v("."),
            ]),
            _vm._v(" Are you sure you want to continue? "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }